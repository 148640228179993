"use client";

import WebIcon from "@mui/icons-material/Web";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { LayoutBox } from "shared";
import ClientFooter from "./ClientFooter";
import FooterButton from "./FooterButton";

const ModalWrapper = dynamic(() => import("./ModalWrapper"), {
  ssr: false,
});

const noFooterPaths = ["/download"]; // Footer를 표시하지 않을 경로들
const shouldHideFooter = (path: string): boolean => {
  return noFooterPaths.some((keyword) => path.includes(keyword));
};
export default function Footer() {
  const pathname = usePathname();
  if (shouldHideFooter(pathname)) {
    return null;
  }

  return (
    <div
      className="absolute left-0 w-full flex justify-center"
      style={{
        backgroundImage: "url(/image/footer.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <LayoutBox type="default">
        <div className="flex flex-col sm:flex-row my-10">
          <Link className="relative w-[100px] h-[40px] mr-7 mb-7" href="/">
            <Image
              src="/logo/logo-dark-beta3.svg"
              alt="logo dark"
              fill
              sizes="(max-width: 640px) 100px, 200px"
              className="object-contain"
            />
          </Link>

          <div>
            <FooterTitle title="예보크 컨설팅 | 고객 센터">
              <div className="flex flex-col">
                <FooterItem>
                  운영시간. 10:00 ~ 22:00 (주말, 공휴일 제외)
                </FooterItem>
                <div className="flex flex-col md:flex-row">
                  <FooterItem>전화 문의. 1599-8884</FooterItem>
                  <FooterItem>팩스. 02-562-0000</FooterItem>
                  <FooterItem>이메일. help@minlab.net</FooterItem>
                </div>
              </div>
              <ClientFooter />
            </FooterTitle>
            <FooterTitle title="사업자 정보">
              <div className="flex flex-col md:flex-row">
                <FooterItem>업체명. 주식회사 예보크</FooterItem>
                <FooterItem>대표이사. 민성원</FooterItem>
                <FooterItem>
                  개인정보관리책임자. 정재훈 minlab@daum.net
                </FooterItem>
              </div>
              <div className="flex flex-col md:flex-row">
                <FooterItem>주소. 서울 강남구 삼성로 72길 3, B1층</FooterItem>
                <FooterItem>사업자 등록번호. 277-87-02439</FooterItem>
              </div>
            </FooterTitle>
            <FooterTitle title="서비스 약관">
              <div className="flex flex-col md:flex-row">
                <ModalWrapper href={"?terms=use"}>
                  <FooterItem>
                    이용약관
                    <WebIcon sx={{ fontSize: 12, ml: "4px" }} />
                  </FooterItem>
                </ModalWrapper>
                <ModalWrapper href={"?terms=privacy"}>
                  <FooterItem>
                    개인정보처리방침
                    <WebIcon sx={{ fontSize: 12, ml: "4px" }} />
                  </FooterItem>
                </ModalWrapper>
                <ModalWrapper href={"?terms=marketing"}>
                  <FooterItem>
                    마케팅정보수신약관
                    <WebIcon sx={{ fontSize: 12, ml: "4px" }} />
                  </FooterItem>
                </ModalWrapper>
              </div>
              <FooterItem>
                COPYRIGHT © 2022 주식회사 예보크 ALL RIGHTS RESERVED.
              </FooterItem>
              <FooterButton
                title="예보크 홈페이지"
                href="https://www.yeboc.co.kr/"
              />
              <FooterButton
                title="민성원 연구소 홈페이지"
                href="https://minlab.co.kr/"
              />
            </FooterTitle>
          </div>
        </div>
      </LayoutBox>
    </div>
  );
}

function FooterTitle({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}): React.ReactNode {
  return (
    <div className="text-xs mb-8">
      <div className="font-bold mb-2">{title}</div>
      {children}
    </div>
  );
}

function FooterItem({ children }: { children: React.ReactNode }) {
  return <div className="mb-2 mr-4 flex items-center">{children}</div>;
}
