"use client";

import { FieldProps } from "types/account";
import TextField from "@mui/material/TextField";

const NameField: React.FC<FieldProps> = ({
  type,
  register,
  errors,
  disabled,
}) => {
  const helperText = (errors[type]?.message || " ").toString();

  return (
    <TextField
      {...register(type)}
      id={type}
      fullWidth
      error={!!errors[type]}
      helperText={helperText}
      autoComplete="name"
      disabled={disabled}
    />
  );
};

export default NameField;
