"use client";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import useTerms from "hooks/useTerms";
import marketing from "mock/terms/marketing.json";
import privacy from "mock/terms/privacy.json";
import use from "mock/terms/use.json";
import { useSearchParams } from "next/navigation";
import { Button } from "shared";

export default function TermsDialog() {
  const { closeTerms } = useTerms();
  const searchParams = useSearchParams();
  const terms = searchParams.get("terms") as "use" | "privacy" | "marketing";
  const data = {
    use,
    privacy,
    marketing,
  }[terms];

  return (
    <Dialog
      open={!!terms}
      onClose={closeTerms}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        sx={{ borderBottom: "1px solid #ebebeb" }}
      >
        {data?.title}
      </DialogTitle>
      <DialogContent>
        <pre className="w-full whitespace-pre-wrap break-words overflow-x-hidden max-w-full leading-5 py-4 text-sm text-[#111]">
          {data?.content}
        </pre>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeTerms}
          autoFocus
          id="terms-dialog-close-button"
          aria-label="닫기"
        >
          닫기
        </Button>
      </DialogActions>
    </Dialog>
  );
}
