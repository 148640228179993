"use client";

import { Button, ButtonProps } from "@mui/material";

import ga from "lib/log";
import React from "react";

interface CustomMuiButtonProps extends ButtonProps {
  id: string;
  "aria-label": string;
}

class CustomMuiButton extends React.Component<CustomMuiButtonProps> {
  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { ["aria-label"]: label, id } = this.props;
    const type = this.props.type || "button";

    if (!label) {
      console.warn("aria-label이 없습니다.");
      return;
    }
    if (!id) {
      console.warn("id가 없습니다.");
      return;
    }

    ga.button(type, label, id);

    if (this.props.onClick) {
      this.props.onClick(event);
    }
  };

  render() {
    return <Button {...this.props} onClick={this.handleClick} />;
  }
}

export default CustomMuiButton;
