interface Props {
  children: React.ReactNode;
  type?: undefined | "sm" | "full" | "default" | "dynamic";
  size?: number;
}

export default function LayoutBox({ children, type, size }: Props) {
  if (type === "dynamic" && size) {
    return (
      <div className="w-full" style={{ maxWidth: `${size}px` }}>
        {children}
      </div>
    );
  }

  if (type === "sm") {
    return (
      <div className={`max-w-[500px] max-h-full w-full mx-auto px-6`}>
        {children}
      </div>
    );
  }

  return (
    <div className={`max-w-[1280px] max-h-full w-full mx-auto px-6`}>
      {children}
    </div>
  );
}
