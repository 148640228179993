"use client";

import React, { useEffect, useRef } from "react";

import { Typography } from "@mui/material";
import Link from "next/link";
import { Button } from "shared";

interface Wave {
  y: number;
  length: number;
  amplitude: number;
  frequency: number;
  phase: number;
  speed: number;
}

interface Star {
  x: number;
  y: number;
  size: number;
  alpha: number;
  alphaChange: number;
}

const WaveMainBanner: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    let animationFrameId: number;
    let time = 0;

    const waves: Wave[] = Array.from({ length: 8 }, (_, i) => {
      const isSlowWave = Math.random() < 0.3;
      return {
        y: Math.random(),
        length: 0.01 + Math.random() * 0.02,
        amplitude: 10 + Math.random() * 20,
        frequency: 0.2 + Math.random() * 0.4,
        phase: Math.random() * Math.PI * 2,
        speed: isSlowWave
          ? 0.00005 + Math.random() * 0.0001
          : 0.0005 + Math.random() * 0.002,
      };
    });

    const stars: Star[] = [];

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = 500;
    };

    const createStar = () => {
      const x = Math.random() * canvas.width;
      const y = Math.random() * canvas.height;
      const size = 1 + Math.random() * 3; // 1 to 4
      stars.push({
        x,
        y,
        size,
        alpha: 0.1 + Math.random() * 0.3, // 0.1 to 0.4
        alphaChange: 0.002 + Math.random() * 0.005, // 변화 속도
      });
    };

    const drawWaves = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      waves.forEach((wave, index) => {
        drawWave(ctx, wave, time, index);
        wave.phase += wave.speed;
      });

      drawShiningStars(ctx);

      time += 0.005;
      animationFrameId = requestAnimationFrame(drawWaves);
    };

    const drawWave = (
      ctx: CanvasRenderingContext2D,
      wave: Wave,
      time: number,
      index: number
    ) => {
      ctx.beginPath();

      for (let x = 0; x < canvas.width; x++) {
        const y =
          Math.sin(x * wave.length + time * wave.frequency + wave.phase) *
            wave.amplitude +
          wave.y * canvas.height;

        ctx.lineTo(x, y);
      }

      ctx.strokeStyle = `rgba(255, 255, 255, ${0.2 - index * 0.02})`;
      ctx.lineWidth = 1.5;
      ctx.stroke();
    };

    const drawShiningStars = (ctx: CanvasRenderingContext2D) => {
      if (stars.length < 25) {
        createStar();
      }

      stars.forEach((star, index) => {
        ctx.save();
        ctx.translate(star.x, star.y);
        ctx.rotate(Math.PI / 4); // 45도 회전

        ctx.beginPath();
        ctx.moveTo(0, -star.size);
        ctx.lineTo(star.size / 3, -star.size / 3);
        ctx.lineTo(star.size, 0);
        ctx.lineTo(star.size / 3, star.size / 3);
        ctx.lineTo(0, star.size);
        ctx.lineTo(-star.size / 3, star.size / 3);
        ctx.lineTo(-star.size, 0);
        ctx.lineTo(-star.size / 3, -star.size / 3);
        ctx.closePath();

        const gradient = ctx.createRadialGradient(0, 0, 0, 0, 0, star.size);
        gradient.addColorStop(0, `rgba(255, 255, 255, ${star.alpha})`);
        gradient.addColorStop(1, "rgba(255, 255, 255, 0)");

        ctx.fillStyle = gradient;
        ctx.fill();

        ctx.restore();

        // 별 반짝임 효과
        star.alpha += star.alphaChange;
        if (star.alpha > 0.7 || star.alpha < 0.1) {
          star.alphaChange = -star.alphaChange;
        }

        // 화면 밖으로 나간 별 제거
        if (star.y < 0 || star.y > canvas.height) {
          stars.splice(index, 1);
        }
      });
    };

    resizeCanvas();
    drawWaves();

    window.addEventListener("resize", resizeCanvas);

    return () => {
      cancelAnimationFrame(animationFrameId);
      window.removeEventListener("resize", resizeCanvas);
    };
  }, []);

  return (
    <>
      <div
        style={{
          position: "relative",
          height: "700px",
          background: "#1d2736",
          padding: "20px",
          display: "flex",
          marginTop: "-70px",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <canvas
          ref={canvasRef}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        />
        <div
          style={{
            position: "relative",
            zIndex: 2,
            textAlign: "center",
            maxWidth: "800px",
            padding: "20px",
            borderRadius: "10px",
          }}
        >
          <Typography
            variant="h2"
            component="h1"
            sx={{
              fontWeight: 600,
              marginBottom: "15px",
              color: "#ffffff",
            }}
          >
            입시 컨설팅의 새로운 패러다임
          </Typography>
          <Typography
            variant="h1"
            sx={{ fontWeight: 700, marginBottom: "20px", fontSize: "2.6rem" }}
          >
            예보크 컨설팅
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 5,
              mb: 5,
              color: "rgba(255,255,255,0.9)",
              fontWeight: 400,
              fontSize: "1.2rem",
            }}
          >
            모두에겐 각자 다른 길이 있습니다
            <br />
            2억개 이상의 경우의 수를 가진 온라인 컨설팅을 경험해보세요
          </Typography>
          <Link href="/product/1">
            <Button
              variant="contained"
              color="white"
              size="large"
              id="main-banner-link-button"
              aria-label="바로가기"
              sx={{
                padding: "10px 30px",
                fontSize: "1.1rem",
                fontWeight: "bold",
                borderRadius: "100px",
              }}
            >
              바로가기
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default WaveMainBanner;
