"use client";

import { FormControl, FormHelperText, Stack } from "@mui/material";
import { FieldProps, Gender } from "types/account";

const GENDER_OPTIONS: { value: Gender; label: string }[] = [
  { value: "MALE", label: "남성" },
  { value: "FEMALE", label: "여성" },
];

const GenderField: React.FC<FieldProps> = ({
  type,
  register,
  errors,
  watch,
  disabled,
}) => {
  const { onChange, name } = register(type);
  const selectedValue = watch("gender");
  const helperText = (errors[type]?.message || " ").toString();
  const handleChange = (value: Gender) => {
    onChange({ target: { name, value } });
  };

  return (
    <>
      <Stack spacing={{ xs: 1, sm: 2 }} direction="row">
        <FormControl
          component="fieldset"
          error={!!errors[type]}
          className="w-full"
        >
          <div className="flex">
            {GENDER_OPTIONS.map((option) => (
              <div
                key={option.value}
                onClick={() => {
                  if (disabled) return;
                  handleChange(option.value);
                }}
                className={`cursor-pointer transition-all duration-200 ease-in-out flex-1 font-semibold text-center items-center flex justify-center text-sm h-[56px]
              ${
                selectedValue === option.value
                  ? "bg-blue-500 text-white"
                  : "bg-gray-100 text-gray-700"
              }
              ${disabled ? "pointer-events-none" : ""}
              `}
                role="button"
                tabIndex={0}
                aria-pressed={selectedValue === option.value}
              >
                {option.label}
              </div>
            ))}
          </div>
          <FormHelperText error={!!errors[type]}>{helperText}</FormHelperText>
        </FormControl>
      </Stack>
    </>
  );
};

export default GenderField;
