"use client";

import { FieldProps, InputType } from "types/account";
import {
  BirthDateField,
  CreateEmailField,
  EmailField,
  GenderField,
  NameField,
  PasswordField,
  PhoneField,
} from "./fields";

import { FieldLabel } from "shared";

const UserInputField: React.FC<{ fieldData: FieldProps }> = ({ fieldData }) => {
  const FieldComponents: Record<InputType, React.ComponentType<FieldProps>> = {
    name: NameField,
    gender: GenderField,
    email: EmailField,
    createEmail: CreateEmailField,
    password: PasswordField,
    birthDate: BirthDateField,
    phone: PhoneField,
  };

  const FieldComponent = FieldComponents[fieldData.type];

  return (
    <div className="my-4">
      <FieldLabel text={fieldData.label || ""} />
      {FieldComponent && <FieldComponent {...fieldData} />}
    </div>
  );
};

export default UserInputField;
