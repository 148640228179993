import ReactGA from "react-ga4";

class LogEvent {
  // user profile을 set하면 자동으로 보내지는 event
  set = ({
    birth,
    email,
    gender,
    id,
  }: {
    birth: string;
    email: string;
    gender: string;
    id: number;
  }) => {
    ReactGA.set({ birth, email, gender, id });
  };

  // custom mui 버튼 클릭하면 자동으로 보내지는 event
  button = (
    type: "button" | "submit" | "reset" | undefined,
    label: string,
    id: string
  ) => {
    ReactGA.event("button_click", {
      type,
      label,
      id,
    });
  };
}

const logEvent = new LogEvent();
export default logEvent;
