import { UseQueryOptions, useQuery } from "@tanstack/react-query";

import { EmailValidText } from "constants/enum/validationText";
import api from "lib/api";
import { useMemo } from "react";

interface EmailCheckResponse {
  data: boolean;
}

interface EmailCheckResult {
  isAvailable: boolean;
  validText: EmailValidText;
}

interface UseEmailCheckResult {
  isPending: boolean;
  isError: boolean;
  error: Error | null;
  refetch: () => Promise<any>;
  isAvailable: boolean | null;
  validText: EmailValidText;
}

const useCreateEmailFieldQueries = () => {
  const useEmailCheck = (
    email: string,
    options?: UseQueryOptions<EmailCheckResponse, Error, EmailCheckResult>
  ): UseEmailCheckResult => {
    const query = useQuery<EmailCheckResponse, Error, EmailCheckResult>({
      queryKey: ["public", "emailCheck", email],
      queryFn: async () => {
        const data = await api.getPublicEmailCheck(email);
        return api.getPublicEmailCheck(email);
      },
      enabled: false,
      select: (data) => ({
        isAvailable: !data.data,
        validText: data.data
          ? EmailValidText.Unavailable
          : EmailValidText.Available,
      }),
      ...options,
    });

    const isPending = useMemo(
      () => query.isLoading || query.isFetching,
      [query.isLoading, query.isFetching]
    );

    return {
      isPending,
      isError: query.isError,
      error: query.error,
      refetch: query.refetch,
      isAvailable: query.data?.isAvailable ?? null,
      validText: query.data?.validText ?? EmailValidText.Valid,
    };
  };

  return { useEmailCheck };
};

export default useCreateEmailFieldQueries;
