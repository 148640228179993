import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/useReducer";

import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { hideModal } from "features/modalSlice";
import Link from "next/link";
import { Button } from "shared";

const Type = "loginModal";
export default function LoginModal() {
  const dispatch = useAppDispatch();
  const { type } = useAppSelector((state) => state.modal);
  const handleClose = () => {
    dispatch(hideModal());
  };

  return (
    <Dialog
      open={type === Type}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        sx={{
          display: "flex",
          textAlign: "center",
          flexDirection: "column",
          alignItems: "center",
          mt: 2,
          mb: 1,
          fontSize: "16px",
        }}
      >
        <TaskAltIcon sx={{ fontSize: "50px", mb: 1 }} />
        이 기능을 사용하려면 로그인이 필요합니다. <br /> 계속하려면
        로그인해주세요
      </DialogTitle>
      <DialogContent sx={{ padding: "20px" }}>
        <Stack spacing={1}>
          <Link href="/account/signin/">
            <Button
              id="link-signin-button"
              aria-label="로그인 페이지로 가기"
              fullWidth
              variant="contained"
              onClick={handleClose}
              sx={{ py: 2 }}
            >
              로그인 페이지로 가기
            </Button>
          </Link>
          <Link href="/">
            <Button
              id="link-home-button"
              aria-label="메인 페이지로 가기"
              fullWidth
              variant="outlined"
              onClick={handleClose}
            >
              메인 페이지로 가기
            </Button>
          </Link>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
