import Link from "next/link";
import { Fragment } from "react";

const ParseTextToLink: React.FC<{ text: string }> = ({ text }) => {
  const parseLinkText = (input: string) => {
    const regex = /\{([^\}]+)\}\[([^\]]+)\]/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(input)) !== null) {
      if (match.index > lastIndex) {
        parts.push(input.slice(lastIndex, match.index));
      }
      parts.push({
        text: match[1],
        link: match[2],
      });
      lastIndex = regex.lastIndex;
    }

    if (lastIndex < input.length) {
      parts.push(input.slice(lastIndex));
    }

    return parts;
  };

  const parsedParts = parseLinkText(text);

  return (
    <>
      {parsedParts.map((part, index) => {
        if (typeof part === "string") {
          return <Fragment key={index}>{part}</Fragment>;
        } else {
          return (
            <Link
              key={index}
              href={part.link}
              target="_blank"
              className="underline mr-[2px]"
            >
              {part.text}
            </Link>
          );
        }
      })}
    </>
  );
};

function formatToCurrency(amount: number): string {
  const absAmount = Math.abs(amount);
  const formattedNumber = absAmount.toLocaleString("ko-KR", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  if (amount === 0) {
    return "0원";
  }

  const result = `${formattedNumber}원`;
  return amount < 0 ? `-${result}` : result;
}

export { ParseTextToLink, formatToCurrency };
