export enum EmailValidText {
  Valid = "",
  Required = "이메일을 입력해주세요",
  Invalid = "이메일 형식으로 작성해주세요",
  Unavailable = "이미 사용중인 이메일 입니다",
  Available = "사용 가능한 이메일입니다.",
}

export enum PasswordValidText {
  Valid = "",
  Required = "비밀번호를 입력해주세요",
  Invalid = "비밀번호는 10자 이상이어야 합니다",
  Policy = "숫자 혹은 특수문자를 포함해야 합니다",
}

export enum ResetPasswordSubmitValidMessage {
  Valid = "",
  SendMailError = "이메일 전송에 실패했습니다. 올바른 이메일인지 확인해주세요.",
  Error = "비밀번호 변경에 문제가 발생했습니다",
  Expiry = "비밀번호 변경 가능 시간 10분이 만료되었습니다. 다시 시도해주세요.",
}

export enum ResetMyPasswordValidText {
  Valid = "",
  Success = "비밀번호가 성공적으로 변경되었습니다",
  Error = "비밀번호 변경에 실패했습니다",
}

export enum LoginValidText {
  Valid = "",
  Empty = "이메일과 비밀번호를 입력해주세요",
  Invalid = "아이디 혹은 비밀번호를 확인해주세요",
  Unauthorized = "아이디 혹은 비밀번호가 일치하지 않습니다",
  Error = "로그인에 실패했습니다.",
}

export enum SignupSubmitValidMessage {
  Valid = "",
  Error = "회원가입에 실패했습니다",
}

// 이메일 인증 / 이메일 조회
export enum EmailAuthSubmitValidMessage {
  Valid = "",
  Error = "이메일 전송에 실패했습니다",
  Expiry = "인증 가능 시간 10분이 만료되었습니다. 다시 시도해주세요.",
  Invalid = "인증 코드가 일치하지 않습니다",
}

// 회원탈퇴
export enum DeleteAccountValidText {
  Valid = "",
  Error = "회원탈퇴에 실패했습니다",
  EmailError = "이메일이 일치하지 않습니다",
}

// 기본정보 수정
export enum UpdateProfileValidText {
  Valid = "",
  Invalid = "수정사항을 확인해주세요",
  Error = "일시적인 오류로 회원정보 수정에 실패했습니다",
}
