"use client";

import { useAppDispatch, useAppSelector } from "hooks/useReducer";

import { Box } from "@mui/material";
import { setProducts } from "features/productSlice";
import api from "lib/api";
import { useEffect } from "react";
import AssessmentItem from "./AssessmentItem";

export default function AssessmentList() {
  const dispatch = useAppDispatch();
  const { products } = useAppSelector((state) => state.product);

  useEffect(() => {
    api.getPublicAssessment().then((res) => {
      dispatch(setProducts(res.data));
    });
  }, [dispatch]);

  return (
    <Box>
      {products.map((val, idx) => {
        return <AssessmentItem key={val.id || idx} val={val} />;
      })}
    </Box>
  );
}
