"use client";

import TextField from "@mui/material/TextField";
import { FieldProps } from "types/account";
import { formatBirthDate } from "utils/formatting";

const BirthDateField: React.FC<FieldProps> = ({
  id,
  type,
  register,
  errors,
  watch,
  disabled,
}) => {
  const { onChange, name } = register(type);
  const helperText = (errors[type]?.message || " ").toString();
  const value = watch("birthDate");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatBirthDate(e.target.value);
    onChange({ target: { name, value: formatted } });
  };

  return (
    <TextField
      {...register("birthDate")}
      id="birthDate"
      fullWidth
      value={value}
      onChange={handleChange}
      disabled={disabled}
      helperText={helperText}
      placeholder="0000-00-00"
      error={!!errors[id]}
      inputProps={{ maxLength: 10 }}
    />
  );
};

export default BirthDateField;
