import { IconButton, Snackbar } from "@mui/material";
import { useAppDispatch, useAppSelector } from "hooks/useReducer";

import CloseIcon from "@mui/icons-material/Close";
import { hideSnackbar } from "features/snackbarSlice";

export default function CustomSnackbar() {
  const { isOpen, message } = useAppSelector((state) => state.snackbar);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(hideSnackbar());
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  return (
    <div>
      <Snackbar
        open={isOpen}
        autoHideDuration={3000}
        onClose={handleClose}
        sx={{
          "& .MuiSnackbarContent-root": {
            backgroundColor: "#3f65d6",

            color: "#fff",
            fontWeight: 500,
            fontSize: "0.9rem",
          },
        }}
        message={message}
        action={action}
      />
    </div>
  );
}
