"use client";
export default function ErrorFallback({ error }: { error: Error }) {
  return (
    <div className="px-4 py-10 bg-[#0000000a] flex flex-col items-center pattern-diagonalv3 m-2">
      <div className="font-bold">오류가 발생했습니다</div>
      <div className="text-xs mt-1 mb-4">
        사용에 불편을 드려 죄송합니다. 빠르게 문제를 해결하는 중 입니다.
      </div>
      <div className="text-[#777] text-xs">{error?.name}</div>
      <div className="text-[#777] text-xs">{error?.message}</div>
    </div>
  );
}
