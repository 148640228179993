"use client";
export default function FooterButton({
  title,
  href,
}: {
  title: string;
  href: string;
}) {
  return (
    <a href={href} target="_blank">
      <div className="border border-black py-1 px-3 rounded-sm inline-flex hover:opacity-50 mr-4 transition duration-100 mb-2">
        {title}
      </div>
    </a>
  );
}
