"use client";

import { Box, TextField, Typography } from "@mui/material";

import { EmailValidText } from "constants/enum/validationText";
import { useState } from "react";
import { FieldProps } from "types/account";

const EmailField: React.FC<FieldProps> = ({
  id,
  register,
  errors,
  trigger,
  watch,
}) => {
  const [isError, setIsError] = useState<boolean>(!!errors[id]);
  const [emailValidText, setEmailValidText] = useState<EmailValidText>(
    EmailValidText.Valid
  );
  const helperText =
    emailValidText !== "" ? (
      <Typography variant="caption" fontWeight={600}>
        {emailValidText}
      </Typography>
    ) : (
      (errors[id]?.message || " ").toString()
    );

  return (
    <Box display={"flex"}>
      <TextField
        {...register(id)}
        id={id}
        type={"email"}
        fullWidth
        autoComplete="email"
        error={!!errors[id] || isError}
        helperText={helperText}
      />
    </Box>
  );
};

export default EmailField;
