import { Skeleton, Stack } from "@mui/material";

export default function CustomSkeleton({ type }: { type: "my" | undefined }) {
  if (type === "my") {
    return [...Array(3)].map((val, idx) => {
      return (
        <Stack key={idx} spacing={2} padding={3} marginTop={5}>
          <Stack direction="row" spacing={2} alignItems="center">
            <Skeleton
              variant="rounded"
              width={60}
              height={20}
              sx={{ borderRadius: 100 }}
            />
            <Skeleton variant="rounded" width={200} height={20} />
          </Stack>

          <Skeleton variant="rectangular" height={60} />
          <Skeleton variant="rounded" width={"50%"} height={20} />

          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Skeleton variant="rectangular" width={140} height={40} />
            <Skeleton variant="rectangular" width={140} height={40} />
          </Stack>
        </Stack>
      );
    });
  }

  return <div> Skeleton component! </div>;
}
