"use client";

import { useState } from "react";

export const usePasswordVisibility = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => setShowPassword((prev) => !prev);

  return { showPassword, togglePasswordVisibility };
};
