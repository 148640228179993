"use client";

/** @jsxImportSource @emotion/react */
import React, { useCallback, useEffect, useState } from "react";

import { css } from "@emotion/react";
import { SvgIconComponent } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface Props {
  componentRef: React.RefObject<HTMLDivElement>;
}

export default function NavigateToBtn({ componentRef }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const bottomY = componentRef.current?.getBoundingClientRect().bottom;

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
    // 페이지 스크롤 이벤트 리스너 등록
    window.addEventListener("scroll", toggleVisibility);
    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  function scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const scrollToBottom = useCallback(() => {
    window.scrollTo({
      top: bottomY,
      behavior: "smooth",
    });
  }, [bottomY]);

  return (
    <div
      className="fixed flex flex-col"
      css={css`
        bottom: 0.7rem;
        right: 0.7rem;
        z-index: 100;
      `}
    >
      <BtnLayout onClick={scrollToTop} Icon={KeyboardArrowUpIcon} />
      <BtnLayout onClick={scrollToBottom} Icon={KeyboardArrowDownIcon} />
    </div>
  );
}

function BtnLayout({
  onClick,
  Icon,
}: {
  onClick: () => void;
  Icon: SvgIconComponent;
}) {
  return (
    <div
      onClick={onClick}
      css={css`
        background-color: black;
        color: white;
        padding: 0.4rem;
        border-radius: 5px;
        margin-top: 0.5rem;
        transition: 0.2s;
        cursor: pointer;
        &:hover {
          background-color: #00000099;
        }
      `}
    >
      <Icon sx={{ color: "white" }} />
    </div>
  );
}
