import { Box, Typography } from "@mui/material";

import SmsFailedIcon from "@mui/icons-material/SmsFailed";

export default function EmptyList({ text }: { text: string }) {
  return (
    <Box
      my={14}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <SmsFailedIcon sx={{ color: "#ccc", fontSize: 40, mb: 3 }} />
      <Typography variant="h5" color="#ccc">
        {text}
      </Typography>
    </Box>
  );
}
