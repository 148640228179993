"use client";

import { TextField } from "@mui/material";
import { FieldProps } from "types/account";
import { formatPhone } from "utils/formatting";

const PhoneField: React.FC<FieldProps> = ({
  type,
  register,
  errors,
  watch,
  disabled,
}) => {
  const helperText = (errors[type]?.message || " ").toString();
  const { onChange } = register("phone");
  const value = watch("phone");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = formatPhone(e.target.value);
    onChange({ target: { name: "phone", value: formatted } });
  };

  console.log(errors, helperText);
  return (
    <TextField
      {...register("phone")}
      id="phone"
      fullWidth
      value={value}
      onChange={handleChange}
      error={!!errors[type]}
      helperText={helperText}
      disabled={disabled}
      autoComplete="tel"
    />
  );
};

export default PhoneField;
