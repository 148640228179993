"use client";

import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";

import { EmailValidText } from "constants/enum/validationText";
import { FieldLabel } from "shared";
import { FieldProps } from "types/account";
import api from "lib/api";
import { isPending } from "@reduxjs/toolkit";
import useCreateEmailFieldQueries from "./CreateEmailField.queries";
import { useState } from "react";
import useTimer from "hooks/useTimer";

const CreateEmailField: React.FC<FieldProps> = ({
  id,
  register,
  errors,
  watch,
  buttonHandler,
  trigger,
}) => {
  const { useEmailCheck } = useCreateEmailFieldQueries();
  const email = watch(id);
  const { isPending, isError, error, refetch, isAvailable, validText } =
    useEmailCheck(email);

  const handleEmailVerification = () => {
    if (email) {
      refetch();
    }
  };

  // const { startTimer, formatTime } = useTimer(600); // 10분 = 600초
  // const helperText =
  //   validText !== "" ? (
  //     <Typography variant="caption" fontWeight={600}>
  //       {validText}
  //     </Typography>
  //   ) : (
  //     (errors[id]?.message || " ").toString()
  //   );

  return (
    <>
      <Box display={"flex"}>
        <TextField
          {...register(id)}
          id={id}
          type={"email"}
          fullWidth
          autoComplete="email"
          error={!!errors[id]}
          helperText={(errors[id]?.message || " ").toString()}
        />
        {/* <Button
          variant="contained"
          color="dark"
          onClick={handleEmailVerification}
          sx={{ height: 54, ml: 2, flexBasis: 100 }}
        >
          {isPending ? (
            <CircularProgress size={20} sx={{ color: "white" }} />
          ) : (
            "검색"
          )}
        </Button> */}
      </Box>

      {/* <div className="my-4 mt-8">
        <FieldLabel text="이메일 인증 코드" />
        <Box display="flex">
          <TextField
            {...register("emailAuthCode")}
            id="emailAuthCode"
            type="text"
            fullWidth
            helperText={`인증 유효 시간 ${formatTime()}`}
          />
          {id === "createEmail" && (
            <Button
              variant="contained"
              color="dark"
              onClick={onSubmit}
              sx={{ height: 54, ml: 2, flexBasis: 100 }}
            >
              {pending ? (
                <CircularProgress size={20} sx={{ color: "white" }} />
              ) : (
                "인증"
              )}
            </Button>
          )}
        </Box>
      </div> */}
    </>
  );
};

export default CreateEmailField;
