"use client";

import { Box, BoxProps, Stack, Typography, styled } from "@mui/material";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Link from "next/link";
import { formatToCurrency } from "utils/parsing";

const SoftShadowBox = styled(Box)<BoxProps>(({ theme }) => ({
  boxShadow: `
    0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028),
    0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042),
    0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07)
  `,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    boxShadow: `
      0 5.6px 4.4px rgba(0, 0, 0, 0.025),
      0 13.4px 10.6px rgba(0, 0, 0, 0.035),
      0 25px 20px rgba(0, 0, 0, 0.045),
      0 44.6px 35.8px rgba(0, 0, 0, 0.055),
      0 83.6px 66.8px rgba(0, 0, 0, 0.065),
      0 200px 160px rgba(0, 0, 0, 0.09)
    `,
    transform: "translateY(-3px)",
  },
}));
export default function AssessmentItem({ val }: { val: any }) {
  console.log(val);
  return (
    <>
      <SoftShadowBox>
        <Box display={"flex"} flexDirection={["column", "row"]} mb={4}>
          <Box
            flex={1}
            padding={["180px", 8]}
            sx={{
              bgcolor: "#f2f2f2",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "350px",
              backgroundImage: "url(/image/thumb.jpg)",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* <div className="relative w-[350px] h-[300px] mr-7 mb-7">
              <Image
                src="/image/thumb.jpg"
                alt="logo dark"
                fill
                sizes="(max-width: 640px) 100px, 200px"
                className="object-contain"
              />
            </div> */}
          </Box>
          {/* <Box
            flex={1}
            padding={[8, 0]}
            sx={{
              bgcolor: "#f2f2f2",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "350px",
            }}
          >
            <ImageIcon sx={{ fontSize: 100, opacity: 0.1 }} />
          </Box> */}
          <Stack flex={1} py={4} px={[4, 6]} justifyContent={"space-between"}>
            <div>
              <Typography variant="h2" mb={1}>
                {val.title}
              </Typography>
              <Typography variant="body1" mb={1}>
                <strong>{formatToCurrency(val.price)}</strong>{" "}
                <span className="mx-1">∙</span> 소요 시간 15분
              </Typography>
              {/* <Typography variant="h4" color={"#555555"}>
                오프라인 현장 결제만 가능
              </Typography> */}

              <Typography variant="body1" my={3}>
                {val.description}
              </Typography>

              <Typography variant="body1" mb={3}>
                #수능 #로드맵컨설팅 #초중고전학년대상 #학습전략 #교재추천
              </Typography>
            </div>

            <Link href={`/product/${val.id}`}>
              <div className="border border-black rounded-xl py-1 px-4 inline-flex hover:opacity-30 transition duration-150">
                자세히 보기 <KeyboardArrowRightIcon />
              </div>
            </Link>
          </Stack>
        </Box>
      </SoftShadowBox>

      {/* 추가로 나올 아이템 */}
      {/* <SoftShadowBox mb={4}>
        <Box display="flex" sx={{ position: "relative" }}>
          <div className="absolute w-full h-full flex items-center justify-center">
            10월 고교 뭐시기 검사 10월 오픈 예정
          </div>
          <Box
            flex={1}
            sx={{
              bgcolor: "#f2f2f2",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ImageIcon sx={{ fontSize: 100, opacity: 0.1 }} />
          </Box>
          <Stack flex={1} py={4} px={6} className="blur">
            <Typography variant="h2" mb={1}>
              [10월 오픈] 고교 뭐시기 검사
            </Typography>
            <Typography variant="body1" mb={1}>
              <strong>금액 미정</strong> <span className="mx-1">∙</span> 소요
              시간 10분
            </Typography>
            <Typography variant="h4" color={"#555555"}>
              오프라인 현장 구매 가능
            </Typography>

            <Typography variant="body1" my={3}>
              어떤 검사냐면, 몇분동안 설문을 진행하고 그걸 기반으로 4개의 대학에
              대한 적합도를 준다. 그리고 과목별로 상게하게 뭐 해주고 그리고 또
              환경이나 뭐시기에 대해서도 알려준다. 몇가지의 선택지가 있어,
              유니크한 결과 값을 확인할 수 있다. 결과를 바탕으로 컨설턴트와 더
              디테일한 상담도 가능하다.
            </Typography>

            <Link href={"/product/1"}>
              <div className="border border-black rounded-xl py-1 px-4 inline-flex hover:opacity-30 transition duration-150">
                자세히 보기 <KeyboardArrowRightIcon />
              </div>
            </Link>
          </Stack>
        </Box>
      </SoftShadowBox> */}
    </>
  );
}
