"use client";

import { useEffect, useState } from "react";

declare const window: any;

export default function useSendMail() {
  const [body, setBody] = useState("");
  const recipient = "help@minlab.net";
  const subject = encodeURIComponent("예보크 웹 버그 제보");
  const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${body}`;

  useEffect(() => {
    setBody(
      encodeURIComponent(
        `
        안녕하세요. 예보크 팀입니다.

        버그 제보를 해주셔서 감사합니다. 버그에 대한 자세한 내용을 제공해 주세요.\n
        관련 스크린샷이나 동영상이 있으면 함께 첨부해 주시면 더욱 빠르게 처리해 드릴 수 있습니다.\n
        감사합니다.\n
        OS: ${getOS()}, Browser: ${getBrowser()}, URL: ${window.location.href}\n
        ----------------------------------------------------------------------------------------------------------------------------
        `
      )
    );
  }, []);

  function getOS() {
    const userAgent = window.navigator.userAgent;
    let os = "Unknown";

    if (userAgent.indexOf("Win") !== -1) os = "Windows";
    else if (userAgent.indexOf("Mac") !== -1) os = "MacOS";
    else if (userAgent.indexOf("X11") !== -1) os = "UNIX";
    else if (userAgent.indexOf("Linux") !== -1) os = "Linux";
    else if (userAgent.indexOf("Android") !== -1) os = "Android";
    else if (userAgent.indexOf("like Mac") !== -1) os = "iOS";

    return os;
  }

  function getBrowser() {
    const userAgent = navigator.userAgent;
    let browser = "Unknown";

    if (userAgent.indexOf("Chrome") > -1) {
      browser = "Chrome";
    } else if (userAgent.indexOf("Safari") > -1) {
      browser = "Safari";
    } else if (userAgent.indexOf("Firefox") > -1) {
      browser = "Firefox";
    } else if (
      userAgent.indexOf("MSIE") > -1 ||
      !!document.DOCUMENT_NODE === true
    ) {
      browser = "Internet Explorer";
    } else if (!!window.StyleMedia) {
      browser = "Edge";
    }

    return browser;
  }

  return { mailtoLink };
}
