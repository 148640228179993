"use client";

import { usePathname, useRouter, useSearchParams } from "next/navigation";

import { eTermsType } from "types/enums";

type TermsDialogType = eTermsType | null;

export default function useTerms() {
  const router = useRouter();
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const openTerms = (termsType: TermsDialogType) => {
    if (!termsType) return;
    router.push(`?terms=${termsType}`, { scroll: false });
  };

  const closeTerms = () => {
    const current = new URLSearchParams(Array.from(searchParams.entries()));
    current.delete("terms");

    const search = current.toString();
    const query = search ? `?${search}` : "";

    router.replace(`${pathname}${query}`, {
      scroll: false,
    });
  };

  return { openTerms, closeTerms };
}
