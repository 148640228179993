export const formatBirthDate = (input: string): string => {
  // 숫자가 아닌 문자 제거
  const cleaned = input.replace(/\D/g, "");

  // 최대 8자리까지만 사용
  const limited = cleaned.slice(0, 8);

  // 년, 월, 일 분리
  const year = limited.slice(0, 4);
  const month = limited.slice(4, 6);
  const day = limited.slice(6, 8);

  // 포맷팅
  if (limited.length > 6) {
    return `${year}-${month}-${day}`;
  } else if (limited.length > 4) {
    return `${year}-${month}`;
  } else if (limited.length > 0) {
    return year;
  } else {
    return "";
  }
};

export const validateName = (email: string): string | null => {
  // 이메일 유효성 검사 로직

  return "";
};

export const validateEmail = (email: string): string | null => {
  // 이메일 유효성 검사 로직

  return "";
};

export const validatePassword = (email: string): string | null => {
  // 이메일 유효성 검사 로직

  return "";
};

export const validateBirthDate = (email: string): string | null => {
  // 이메일 유효성 검사 로직

  return "";
};
export const formatPhone = (input: string): string => {
  // 숫자만 추출
  const numbers = input.replace(/[^\d]/g, "");

  // 번호 길이에 따라 포맷팅
  if (numbers.length <= 3) {
    return numbers;
  } else if (numbers.length <= 7) {
    return `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
  } else {
    return `${numbers.slice(0, 3)}-${numbers.slice(3, 7)}-${numbers.slice(7, 11)}`;
  }
};
