"use client";

import useSendMail from "hooks/useSendMail";
import FooterButton from "./FooterButton";

export default function ClientFooter() {
  const { mailtoLink } = useSendMail();

  return <FooterButton title="버그 제보" href={mailtoLink} />;
}
