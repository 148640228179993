"use client";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import { usePasswordVisibility } from "hooks/usePasswordVisibility";
import { FieldProps } from "types/account";

const PasswordField: React.FC<FieldProps> = ({ id, register, errors }) => {
  const { showPassword, togglePasswordVisibility } = usePasswordVisibility();

  const helperText = (errors[id]?.message || " ").toString();

  return (
    <TextField
      {...register(id)}
      id={id}
      type={showPassword ? "text" : "password"}
      fullWidth
      autoComplete="current-password"
      error={!!errors[id]}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={togglePasswordVisibility}
              edge="end"
              sx={{ mr: "0px" }}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
